<template>
  <aside class="iv2-extra-sns text-center d-none d-xl-block">
    <div class="iv2-extra-sns-nav pt-2">
      <div
        class="iv2-extra-sns-nav-block"
        :class="{ active: scrollY > 300, fixed: activeScroll }"
      >
        <ul class="iv2-extra-sns-nav-list list-unstyled">
          <li v-if="sns.is_linked_facebook == 1" class="iv2-extra-sns-nav-item">
            <a
              :href="sns.facebook_url"
              class="iv2-extra-sns-nav-link"
              target="_blank"
              rel="noopener"
            >
              <i class="iv2-extra-sns-nav-item-icon fab fa-facebook-f"></i>
            </a>
          </li>
          <li
            v-if="sns.is_linked_instagram == 1"
            class="iv2-extra-sns-nav-item"
          >
            <a
              :href="sns.instagram_url"
              class="iv2-extra-sns-nav-link"
              target="_blank"
              rel="noopener"
            >
              <i class="iv2-extra-sns-nav-item-icon fab fa-instagram"></i>
            </a>
          </li>
          <li v-if="sns.is_linked_twitter == 1" class="iv2-extra-sns-nav-item">
            <a
              :href="sns.twitter_url"
              class="iv2-extra-sns-nav-link"
              target="_blank"
              rel="noopener"
            >
              <i class="iv2-extra-sns-nav-item-icon fab fa-twitter"></i>
            </a>
          </li>
          <li v-if="sns.is_linked_line == 1" class="iv2-extra-sns-nav-item">
            <a
              :href="sns.line_url"
              class="iv2-extra-sns-nav-link"
              target="_blank"
              rel="noopener"
            >
              <i class="iv2-extra-sns-nav-item-icon fab fa-line"></i>
            </a>
          </li>
          <!-- <li
            v-if="rss.is_linked_blog == 1"
            class="iv2-extra-sns-nav-item"
          >
            <a
              :href="rss.url"
              class="iv2-extra-sns-nav-link"
              target="_blank"
              rel="noopener"
            >
              <i class="iv2-extra-sns-nav-item-icon fas fa-rss"></i>
            </a>
          </li> -->
        </ul>
      </div>
    </div>
  </aside>
</template>

<script>
export default {
  props: {
    sns: Object,
    rss: Object,
  },

  data() {
    return {
      activeScroll: false,
      scrollY: 0,
    };
  },

  watch: {
    $route(to, from) {
      if (to.path !== from.path && this.$route.path !== "/") {
        this.activeScroll = true;
      } else if (to.path !== from.path && this.$route.path == "/") {
        this.activeScroll = false;
      }
    },
  },

  created() {
    if (this.$route.path !== "/") {
      this.activeScroll = true;
    }
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    handleScroll() {
      this.scrollY = window.scrollY;
    },
  },
};
</script>

<style lang="scss">
.iv2-extra-sns {
  background-color: #fff;

  .iv2-extra-sns-nav {
    height: 100%;
    position: relative;

    .iv2-extra-sns-nav-block {
      position: absolute;
      bottom: 110px;
      transition: 1s;

      &.fixed {
        bottom: 0px;
      }

      &.active {
        bottom: 110px;
      }

      .iv2-extra-sns-nav-list {
        margin-top: 60px;
        width: 80px;

        .iv2-extra-sns-nav-link {
          margin: 0 10px;
          color: #000;
        }

        .iv2-extra-sns-nav-item {
          .iv2-extra-sns-nav-item-icon {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
</style>
