<template>
  <aside
    :class="{ active: scrollY > 300 }"
    class="iv2-extra-nav theme__extra-bg text-center d-none d-xl-block"
  >
    <nav class="iv2-extra-nav__sub-menu">
      <ul class="iv2-extra-nav__sub-list list-unstyled">
        <li v-for="nav in extra" :key="nav.id" class="iv2-extra-nav__sub-item">
          <router-link
            v-if="nav.link_type == 'internal'"
            :to="nav.link_href + lang + query"
            :target="nav.target_type"
            class="iv2-extra-nav__sub-link"
          >
            <i
              class="iv2-extra-nav__sub-item-icon d-block"
              :class="nav.item_icon"
            ></i>
            <span class="iv2-extra-nav__sub-item-text d-block">{{
              nav.item_name
            }}</span>
          </router-link>
          <a
            v-else-if="nav.link_type == 'external'"
            :href="nav.link_href"
            :target="nav.target_type"
            rel="noopener"
            class="iv2-extra-nav__sub-link"
          >
            <i
              class="iv2-extra-nav__sub-item-icon d-block"
              :class="nav.item_icon"
            ></i>
            <span class="iv2-extra-nav__sub-item-text d-block">{{
              nav.item_name
            }}</span>
          </a>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script>
export default {
  props: {
    extra: Array,
  },

  data() {
    return {
      scrollY: 0,
      query: "",
      lang: "",
    };
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
  },

  mounted() {
    if (this.$route.query.mode == "preview") {
      this.query = "?mode=preview";
    }
    if (this.$route.query.hl) {
      this.lang = "?hl=" + this.$route.query.hl;
    }
  },

  methods: {
    handleScroll() {
      this.scrollY = window.scrollY;
    },
  },
};
</script>

<style lang="scss">
.iv2-extra-nav {
  top: 110px;
  right: 0;
  transition: 0.3s;

  .iv2-extra-nav__sub-menu {
    .iv2-extra-nav__sub-list {
      margin-bottom: 0;
      height: 100%;

      .iv2-extra-nav__sub-item {
        position: relative;
        color: #fff;

        &::after {
          content: "";
          width: 37px;
          height: 1px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          background-image: linear-gradient(
            to right,
            #fff,
            #fff 1px,
            transparent 1px
          );
          background-size: 4px 1px;
          background-repeat: repeat-x;
        }

        &:last-child {
          &::after {
            content: none;
          }
        }

        .iv2-extra-nav__sub-link {
          padding: 17px 10px;
          -webkit-transition: 0.3s;
          transition: 0.3s;
          display: block;
          color: #fff;

          &:hover {
            text-decoration: none;
            opacity: 0.5;
          }
        }

        .iv2-extra-nav__sub-item-icon {
          font-size: 1.1rem;
          margin-bottom: 6px;
        }

        .iv2-extra-nav__sub-item-text {
          font-size: 8px;
          line-height: 1.3;
        }
      }
    }
  }
}
</style>
