export default {
  mounted() {
    // 初回表示時のスクロール処理
    const hash = this.$route.hash;
    if (hash && hash.match(/^#.+$/)) {
      const findEl = (hash, x) => {
        return (
          document.querySelector(hash) ||
          new Promise((resolve) => {
            if (x > 50) {
              return resolve();
            }
            setTimeout(() => {
              resolve(findEl(hash, ++x || 1));
            }, 500);
          })
        );
      };

      const element = findEl(hash);
      Promise.all([element]).then((value) => {
        return window.scrollTo({
          top: value[0].offsetTop,
          behavior: "smooth",
        });
      });
    }

    // ページ内でアンカーをクリックした際の処理
    this.$nextTick(() => {
      setTimeout(() => {
        const anchorLinks = document.querySelectorAll('a[href*="#"]');
        if (anchorLinks && anchorLinks.length > 0) {
          const anchorLinksArr = Array.prototype.slice.call(anchorLinks);

          if (anchorLinksArr && anchorLinksArr.length > 0) {
            // クリック時のイベントをウォッチ
            anchorLinksArr.forEach((link) => {
              link.addEventListener("click", (e) => {
                if (link.hash) {
                  const hashEl = document.querySelector(link.hash);

                  // 要素がページ内に存在する場合のみ実行
                  if (hashEl) {
                    // デフォルトのアンカー動作を無効化
                    e.preventDefault();

                    // 座標値を取得
                    const targetOffsetTop =
                      window.pageYOffset +
                      document.querySelector(link.hash).getBoundingClientRect().top;

                    // スクロール
                    window.scrollTo({
                      top: targetOffsetTop,
                      behavior: "smooth",
                    });
                  }
                }
              });
            });
          }
        }
      }, 500);
    });
  },
};
