<template>
  <div>
    <article class="iv2-article" v-if="pageActive">
      <HomeScroll />
      <!-- メインビジュアル -->
      <LayoutHero
        :items="items"
        :organization="organization"
        :bottom="bottom"
      />

      <!-- おすすめ記事 -->
      <LayoutPickup :items="items" />

      <!-- コンテンツエリア -->
      <LayoutPattern :items="items" />

      <!-- ニュース -->
      <LayoutNews :items="items" :rss="rss" @setNews="refNewsArticles" />

      <!-- ブログ -->
      <LayoutBlog :items="items" />

      <!-- 外部RSS -->
      <LayoutRss :rss="rss" />
    </article>
    <div v-if="loading">
      <BaseLoading />
    </div>
  </div>
</template>

<script>
import HomeScroll from "./components/HomeScroll.vue";
import LayoutHero from "./components/LayoutHero.vue";
import LayoutBlog from "./components/LayoutBlog.vue";
import LayoutPickup from "./components/LayoutPickup.vue";
import LayoutPattern from "./components/LayoutPattern.vue";
import LayoutNews from "./components/LayoutNews.vue";
import LayoutRss from "./components/LayoutRss.vue";
import BaseLoading from "@/components/items/BaseLoading.vue";
import Meta from "@/mixins/MetaSettable";
import Hash from "@/mixins/AnchorLink";
import Preview from "@/mixins/Preview";

export default {
  components: {
    HomeScroll,
    LayoutHero,
    LayoutBlog,
    LayoutPickup,
    LayoutPattern,
    LayoutNews,
    LayoutRss,
    BaseLoading,
  },

  mixins: [Meta, Hash, Preview],

  props: {
    organization: Object,
    rss: Object,
    bottom: Array,
  },

  data() {
    return {
      items: {},
      pageActive: false,
      loading: true,
    };
  },

  mounted() {
    const topRepository = this.$repository.get("topPage");
    const topPage = topRepository.index(
      this.$route.query.mode,
      this.$route.query.api_token
    );
    const self = this;
    topPage
      .then((result) => {
        self.items = result.data.response;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        self.loading = false;
        self.pageActive = true;
      });
  },

  // customize
  methods: {
    // ニュース記事のDOMを本文中に流し込む処理（#refNewsAreaのセレクタに注入）
    refNewsArticles(news) {
      document.getElementById("refNewsArea").appendChild(news);
    },
  },

};
</script>

<style lang="scss"></style>
