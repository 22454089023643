<template>
  <section class="iv2-page-header">
    <div
      v-if="items.header_type == 'add_catch'"
      class="iv2-page-header-type1 fit-img overflow-hidden"
    >
      <picture>
        <source
          type="image/webp"
          :srcset="
            items.header_image + $store.state.publicLang.general.image_webp
          "
        />
        <img
          class="iv2-page-header__img"
          :src="items.header_image"
          :alt="items.title + $store.state.publicLang.page.alt"
        />
      </picture>
      <div
        class="iv2-page-header__cover theme__page-header-bg--with-catchcopy position-absolute"
      ></div>
      <div class="iv2-page-header__title position-relative">
        <h1 class="iv2-page-header__main-title">{{ items.title }}</h1>
        <p v-if="items.subtitle" class="iv2-page-header__sub-title m-0 mt-1">
          {{ items.subtitle }}
        </p>
      </div>
      <div class="iv2-page-header__text position-relative text-center">
        <div class="row">
          <div class="col-12 px-md-5 px-4">
            <p
              v-if="items.header_subtitle"
              v-html="items.header_subtitle"
              class="iv2-page-header__sub-lead"
            ></p>
            <p
              v-if="items.header_title"
              v-html="items.header_title"
              class="iv2-page-header__main-lead"
            ></p>
            <p
              v-if="items.header_summary_text"
              v-html="items.header_summary_text"
              class="iv2-page-header__description"
            ></p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="items.header_type == 'add_bg'"
      class="iv2-page-header-type1 fit-img overflow-hidden"
    >
      <picture>
        <source
          type="image/webp"
          :srcset="
            items.header_image + $store.state.publicLang.general.image_webp
          "
        />
        <img
          class="iv2-page-header__img"
          :src="items.header_image"
          :alt="items.title + $store.state.publicLang.page.alt"
        />
      </picture>
      <div
        class="iv2-page-header__cover theme__page-header-bg--with-catchcopy position-absolute"
      ></div>
      <div class="iv2-page-header__title position-relative">
        <h1 class="iv2-page-header__main-title h4 m-0">{{ items.title }}</h1>
        <p v-if="items.subtitle" class="iv2-page-header__sub-title m-0 mt-1">
          {{ items.subtitle }}
        </p>
      </div>
    </div>
    <div
      v-else-if="items.header_type == 'add_cover'"
      class="iv2-page-header-type2 overflow-hidden"
    >
      <div class="iv2-page-header__title position-relative">
        <h1 class="iv2-page-header__main-title theme__page-header-title h4 m-0">
          {{ items.title }}
        </h1>
        <p
          v-if="items.subtitle"
          class="iv2-page-header__sub-title theme__page-header-sub-title m-0 mt-1"
        >
          {{ items.subtitle }}
        </p>
      </div>
      <div class="iv2-page-header__img-band fit-img">
        <picture>
          <source
            type="image/webp"
            :srcset="
              items.header_image + $store.state.publicLang.general.image_webp
            "
          />
          <img
            class="iv2-page-header__img"
            :src="items.header_image"
            :alt="items.title + $store.state.publicLang.page.alt"
          />
        </picture>
        <div
          class="iv2-page-header__cover theme__page-header-bg--with-catchcopy position-absolute"
        ></div>
      </div>
    </div>
    <div
      v-else-if="items.header_type == 'text_only'"
      class="iv2-page-header-type3 overflow-hidden"
    >
      <div class="iv2-page-header__title position-relative">
        <h1 class="iv2-page-header__main-title theme__page-header-title h4 m-0">
          {{ items.title }}
        </h1>
        <p
          v-if="items.subtitle"
          class="iv2-page-header__sub-title theme__page-header-sub-title m-0 mt-1"
        >
          {{ items.subtitle }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    items: Object,
  },
};
</script>

<style lang="scss">
.iv2-page-header {
  text-align: center;

  .iv2-page-header__title {
    color: #fff;

    .iv2-page-header__main-title {
      font-size: 1.5rem;
      border-bottom: 1px solid;
      padding: 30px 5px 5px;
      font-weight: bold;
      display: inline-block;
    }

    .iv2-page-header__sub-title {
      font-size: 12px;
      font-weight: 400;
    }
  }

  .iv2-page-header__cover {
    background-color: rgba(0, 0, 0, 0.15);
  }

  .iv2-page-header__img-band {
    .iv2-page-header__cover {
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }

  /* ページヘッダー(タイプ1) */

  .iv2-page-header-type1 {
    padding-bottom: 80px;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);

    .iv2-page-header__img {
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    .iv2-page-header__cover {
      width: 100%;
      height: 100%;
      z-index: 2;
    }

    .iv2-page-header__title,
    .iv2-page-header__text {
      z-index: 3;
      color: #fff;
    }

    .iv2-page-header__text {
      .iv2-page-header__main-lead {
        line-height: 1.2;
      }

      .iv2-page-header__sub-lead {
        line-height: 1.5;
      }
    }
  }
}

/* PC */
@media (min-width: 1200px) {
  .iv2-page-header-type1 {
    .iv2-page-header__title {
      padding: 50px 50px 10px;
    }
    .iv2-page-header__text {
      .iv2-page-header__main-lead {
        font-size: 2rem;
      }

      .iv2-page-header__sub-lead {
        font-size: 1.4rem;
      }

      .iv2-page-header__description {
        margin-top: 30px;
        line-height: 1.3;
        font-size: 1.07rem;
      }
    }
  }

  .iv2-page-header-type2,
  .iv2-page-header-type3 {
    .iv2-page-header__title {
      padding: 80px 50px;
    }
  }

  .iv2-page-header__img-band {
    height: 250px;
  }
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
  .iv2-page-header__title {
    padding: 60px 30px;
  }

  .iv2-page-header-type1 {
    .iv2-page-header__title {
      padding: 30px;
    }

    .iv2-page-header__text {
      .iv2-page-header__description {
        line-height: 1.5;
      }

      .iv2-page-header__main-lead {
        font-size: 2rem;
      }
    }
  }

  .iv2-page-header__img-band {
    height: 150px;
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-page-header {
    .iv2-page-header__title {
      padding: 50px 20px;

      .iv2-page-header__main-title {
        font-size: 1.3rem;
      }
    }

    .iv2-page-header-type1 {
      padding-bottom: 30px;

      .iv2-page-header__text {
        padding: 0 20px;

        .iv2-page-header__main-lead {
          font-size: 1.3rem;
        }

        .iv2-page-header__description {
          font-size: 0.9rem;
        }
      }
    }
  }

  .iv2-page-header__img-band {
    height: 100px;
  }
}
</style>
